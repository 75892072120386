import "./App.css";
// import { baseUrls } from './Config';
import { Route, Routes, useNavigate } from "react-router-dom";
// import { Suspense, lazy } from "react";
import Login from "./pages/Auth/Login";
import ResetPassword from "./pages/Auth/ResetPassword";
import Dashboard from "./pages/Admin Dashboard/Dashboard";
import PrivateComponent from "./private/PrivateComponent";
import Student_Parent from "./pages/Admin Dashboard/Student_Parent/Student_Parent";
import Parent_Learner from "./pages/Admin Dashboard/Student_Parent/Parent_Learner";
import Teacher from "./pages/Admin Dashboard/Teacher/Teacher";
import Organization from "./pages/Admin Dashboard/Organization/Organization";
import Learner_Details from "./pages/Admin Dashboard/Student_Parent/Learner_Details";
import TeacherDetails from "./pages/Admin Dashboard/Teacher/TeacherDetails";
import OrgTeacherData from "./pages/Admin Dashboard/Organization/OrgTeacherData";
import OrgDetails from "./pages/Admin Dashboard/Organization/OrgDetails";
import UserDetails from "./pages/Admin Dashboard/Student_Parent/UserDetails";
import ClassDetails from "./pages/Admin Dashboard/Student_Parent/ClassDetails";
import Category from "./pages/Admin Dashboard/Category/Category";
import CategoryDetails from "./pages/Admin Dashboard/Category/CategoryDetails";
import AddCategory from "./pages/Admin Dashboard/Category/AddCategory";
import EditCategory from "./pages/Admin Dashboard/Category/EditCategory";
import Subject from "./pages/Admin Dashboard/Subject/Subject";
import SubjectDetails from "./pages/Admin Dashboard/Subject/SubjectDetails";
import AddSubject from "./pages/Admin Dashboard/Subject/AddSubject";
import EditSubject from "./pages/Admin Dashboard/Subject/EditSubject";
import RedirectBlankPageHome from "./pages/Common/RedirectBlankPageHome"
import Blog from "./pages/Admin Dashboard/Blog/Blog";
import { Navbar } from "react-bootstrap";
import RedirectBlankPage from "./pages/Common/RedirectBlankPage";
import BlogDetails from "./pages/Admin Dashboard/Blog/BlogDetails";
import AddBlog from "./pages/Admin Dashboard/Blog/AddBlog";
import SubAdminList from "./pages/Admin Dashboard/RoleBaseAccess/SubAdminList";
import CreateSubAdmin from "./pages/Admin Dashboard/RoleBaseAccess/CreateSubAdmin"
import EditBlog from "./pages/Admin Dashboard/Blog/EditBlog";
import Faq from "./pages/Admin Dashboard/TrainingVideos/Faq";
import AddFaq from "./pages/Admin Dashboard/TrainingVideos/AddFaq";
import EditFaq from "./pages/Admin Dashboard/TrainingVideos/EditFaq";
import Video from "./pages/Admin Dashboard/TrainingVideos/Video";
import AddVideo from "./pages/Admin Dashboard/TrainingVideos/AddVideo";
import EditVideo from "./pages/Admin Dashboard/TrainingVideos/EditVideo";
import Pages from "./pages/Admin Dashboard/Pages/Pages";
import ContactUs from "./pages/Admin Dashboard/ContactUs/ContactUs";
import ContactUsDetails from "./pages/Admin Dashboard/ContactUs/ContactUsDetails";
import ContactusReply from "./pages/Admin Dashboard/ContactUs/ContactusReply";
import ClassLearner from "./pages/Admin Dashboard/Student_Parent/ClassLearner"
import UpdateSubAdmin from "./pages/Admin Dashboard/RoleBaseAccess/UpdateSubAdmin";
import SubAdminDetails from "./pages/Admin Dashboard/RoleBaseAccess/SubAdminDetails";
import { useEffect, useState } from "react";
import { admin_permission_detail } from "./api/apiHandler";
import SeoUser from "./pages/Admin Dashboard/SeoUser/SeoUser";
import SeoDetails from "./pages/Admin Dashboard/SeoUser/SeoDetails";
import AddSeoUser from "./pages/Admin Dashboard/SeoUser/AddSeoUser";
import EditSeoUser from "./pages/Admin Dashboard/SeoUser/EditSeoUser";
import VideoDetails from "./pages/Admin Dashboard/TrainingVideos/VideoDetails";
import TApplication from "./pages/Admin Dashboard/Teacher/TApplication";
import TBackground from "./pages/Admin Dashboard/Teacher/TBackground";
import TIdentity from "./pages/Admin Dashboard/Teacher/TIdentity";
import OApplication from "./pages/Admin Dashboard/Organization/OApplication";
import OrgTeacherDetails from "./pages/Admin Dashboard/Organization/OrgTeacherDetails";
import OIdentity from "./pages/Admin Dashboard/Organization/OIdentity";
import OAgreement from "./pages/Admin Dashboard/Organization/OAgreement";
import OAuthorization from "./pages/Admin Dashboard/Organization/OAuthorization";
import OrgTeacherBg from "./pages/Admin Dashboard/Organization/OrgTeacherBg";
import OrgTeacherIdentity from "./pages/Admin Dashboard/Organization/OrgTeacherIdentity";
import Earning from "./pages/Admin Dashboard/Earnings/Earning";
import SubscriptionClass from "./pages/Admin Dashboard/Student_Parent/SubscriptionClass";
import TeachingClass from "./pages/Admin Dashboard/Teacher/TeachingClass";
import LearnerSubscriptionClass from "./pages/Admin Dashboard/Student_Parent/LearnerSubscriptionClass";
import Notification from "./pages/Admin Dashboard/Notification/Notification";
import Reports from "./pages/Admin Dashboard/Reports/Reports";
import FirstReport from "./pages/Admin Dashboard/Reports/FirstReport";
import Classroom from "./pages/Admin Dashboard/Classroom/Classroom";
import ClassDetail from "./pages/Admin Dashboard/Classroom/ClassDetail";
import ClubDetails from "./pages/Admin Dashboard/Classroom/ClubDetails";
import QuizDetails from "./pages/Admin Dashboard/Classroom/QuizDetails";
import CompetitionDetails from "./pages/Admin Dashboard/Classroom/CompetitionDetails";
import SecondReport from "./pages/Admin Dashboard/Reports/SecondReport";
import FourthReport from "./pages/Admin Dashboard/Reports/FourthReport";
import FifthReport from "./pages/Admin Dashboard/Reports/FifthReport";
import SixthReport from "./pages/Admin Dashboard/Reports/SixthReport";
import SeventhReport from "./pages/Admin Dashboard/Reports/SeventhReport";
import EightReport from "./pages/Admin Dashboard/Reports/EightReport";
import NineReport from "./pages/Admin Dashboard/Reports/NineReport";
import TenReport from "./pages/Admin Dashboard/Reports/TenReport";
import EWallet from "./pages/Admin Dashboard/EWallet/EWallet";
import ComplaintDetails from "./pages/Admin Dashboard/Reports/ComplaintDetails";
import NewsLetter from "./pages/Admin Dashboard/NewsLetter/NewsLetter";
import EWalletBalance from "./pages/Admin Dashboard/EWallet/EWalletBalance";
import Messages from "./pages/Admin Dashboard/Messages/Messages";
import Loader from "./utils/Loader";
import OrgTeacherTeachingClass from "./pages/Admin Dashboard/Organization/OrgTeacherTeachingClass";
import RefundList from "./pages/Admin Dashboard/EWallet/RefundList";
import OrgClassList from "./pages/Admin Dashboard/Organization/OrgClassList";
import RefundRequestDetails from "./pages/Admin Dashboard/EWallet/RefundRequestDetails";
import SendNotification from "./pages/Admin Dashboard/Notification/SendNotification";
import TeacherProfile from "./pages/Admin Dashboard/Teacher/TeacherProfile";
import OrgProfile from "./pages/Admin Dashboard/Organization/OrgProfile";
import SubscriptionUsers from "./pages/Admin Dashboard/NewsLetter/SubscriptionUsers";
import AddNewsLetter from "./pages/Admin Dashboard/NewsLetter/AddNewsLetter";
import NewsLetterDetails from "./pages/Admin Dashboard/NewsLetter/NewsLetterDetails";
import PreviewNewsLetter from "./pages/Admin Dashboard/NewsLetter/PreviewNewsLetter";
import Coupons from "./pages/Admin Dashboard/Coupons/Coupons";
import CouponDetails from "./pages/Admin Dashboard/Coupons/CouponDetails";
import New_Leeds from "./pages/Admin Dashboard/NewLeads/Index";
import EwalletGroup from "./pages/Admin Dashboard/EWallet/EwalletGroup";
import PrefferedTeacherDetails from "./pages/Admin Dashboard/NewLeads/PrefferedTeacherDetails";
import NonPrefferedTeacherDetails from "./pages/Admin Dashboard/NewLeads/NonPrefferedTeacherDetails";
import New_Leads_Details from "./pages/Admin Dashboard/NewLeads/New_Leads_Details";
import NewLeadsClassDetails from "./pages/Admin Dashboard/NewLeads/NewLeadsClassDetails";
import Statistics from "./pages/Admin Dashboard/NewLeads/Statistics";
import NewLeadsClassLearner from "./pages/Admin Dashboard/NewLeads/NewLeadsClassLearner";
import RespondedTeacherList from "./pages/Admin Dashboard/NewLeads/RespondedTeacherList";


// import JqueryLoad from './JqueryLoad/JqueryLoad';

// import React, {useState, useEffect} from 'react';

// export const Loadable = (Component) => (props) => {
//   return (
//     <Suspense>
//       <Component {...props} />
//     </Suspense>
//   );
// };

function App() {
  const [isLoading, setIsLoading] = useState(false);

  const showLoder = (isLoading) => {
    setIsLoading(isLoading)
  }

  const [permissions, setPermission] = useState([]);
  const role = localStorage.getItem("adminRole");
  const navigate = useNavigate();
  const AdminId = localStorage.getItem("aID")

  useEffect(() => {
    if (AdminId) {
      admin_permission_detail({ id: AdminId })
        .then((response) => {
          if (response.code === '1') {
            setPermission(response.data);
          } else {
            // console.error("Error fetching admin permissions:", response.message);
          }
        })
        .catch((error) => {
          // console.error("Error fetching admin permissions:", error);
        });
    }
  }, [AdminId])

  if (permissions === null) {
    return <div>Loading...</div>;
  }

  const hasPermission = (route) => {
    if (role === "S") {
      return true;
    }

    const permission = permissions.find(p => p.menu_name === route);
    if (!permission) return false;
    return permission.view === 1;
  };

  const handleBack = () => {
    navigate(-1);
  };

  const AccessDenied = () => {
    return <>
      <div className="access-denied-container">
        <div className="access-denied-content">
          <span onClick={handleBack} className="back-button">
            <svg
              width="10"
              height="18"
              viewBox="0 0 10 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.86172 2.225L8.37839 0.75L0.136719 9L8.38672 17.25L9.86172 15.775L3.08672 9L9.86172 2.225Z"
                fill="#131313"
              ></path>
            </svg>
            <span className="ml-2 back-text">Back</span>
          </span>
          <h1>Access Denied</h1>
          <p className="fs-5">Sorry, you don't have permission to access this page.</p>
        </div>
      </div>
    </>
  };

  // const WithPermission = (Component, permissionCheck) => {
  //   return (props) => {
  //     const hasPermission = permissionCheck(props.route);
  //     return hasPermission ? <Component {...props} /> : <AccessDenied />;
  //   };
  // };

  const getFlow = () => {
    if (!localStorage.getItem("AToken", false)) {
      return (
        <>
          <Loader isActive={isLoading} />
          <Routes>
            <Route path="*" element={<RedirectBlankPage />} />
            <Route path="/login" element={<Login isLoader={showLoder} />} />
            <Route path="/reset-password" element={<ResetPassword />} />
          </Routes>
        </>
      );
    }
    else {
      return (
        <>
          <Loader isActive={isLoading} />

          <Routes>
            <Route path="/dashboard" element={<Dashboard isLoader={showLoder} />} />
            {/* <Route path="/student-parent" element={WithPermission(Student_Parent, "Learner Parents")} /> */}
            <Route path="/student-parent" element={hasPermission("Learner Parents") ? <Student_Parent isLoader={showLoder} /> : <AccessDenied />} />
            <Route path="/parent-learner" element={hasPermission("Learner Parents") ? <Parent_Learner isLoader={showLoder} /> : <AccessDenied />} />
            <Route path="/class-details" element={hasPermission("Learner Parents") ? <ClassDetails isLoader={showLoder} /> : <AccessDenied />} />
            <Route path="/class_learner" element={hasPermission("Learner Parents") ? <ClassLearner isLoader={showLoder} /> : <AccessDenied />} />
            <Route path="/learnerdetails" element={hasPermission("Learner Parents") ? <Learner_Details isLoader={showLoder} /> : <AccessDenied />} />
            <Route path="/user_details" element={hasPermission("Learner Parents") ? <UserDetails isLoader={showLoder} /> : <AccessDenied />} />
            <Route path="/subscription-class-details" element={hasPermission("Learner Parents") ? <SubscriptionClass isLoader={showLoder} /> : <AccessDenied />} />
            <Route path="/learner-subscription-class-details" element={hasPermission("Learner Parents") ? <LearnerSubscriptionClass isLoader={showLoder} /> : <AccessDenied />} />

            <Route path="/teacher" element={hasPermission("Teacher") ? <Teacher isLoader={showLoder} /> : <AccessDenied />} />
            <Route path="/teacher_details" element={hasPermission("Teacher") ? <TeacherDetails isLoader={showLoder} /> : <AccessDenied />} />
            <Route path="/teacher-applications-details" element={hasPermission("Teacher") ? <TApplication isLoader={showLoder} /> : <AccessDenied />} />
            <Route path="/teacher-background-details" element={hasPermission("Teacher") ? <TBackground isLoader={showLoder} /> : <AccessDenied />} />
            <Route path="/teacher-identity-details" element={hasPermission("Teacher") ? <TIdentity isLoader={showLoder} /> : <AccessDenied />} />
            <Route path="/teaching-class-details" element={hasPermission("Teacher") ? <TeachingClass isLoader={showLoder} /> : <AccessDenied />} />
            <Route path="/teacher-profile" element={hasPermission("Teacher") ? <TeacherProfile isLoader={showLoder} /> : <AccessDenied />} />

            <Route path="/organization" element={hasPermission("Organization") ? <Organization isLoader={showLoder} /> : <AccessDenied />} />
            <Route path="/organizationteacherdetails" element={hasPermission("Organization") ? <OrgTeacherData isLoader={showLoder} /> : <AccessDenied />} />
            <Route path="/org_teacher_teaching_class_details" element={hasPermission("Organization") ? <OrgTeacherTeachingClass isLoader={showLoder} /> : <AccessDenied />} />
            <Route path="/org_class_list" element={hasPermission("Organization") ? <OrgClassList isLoader={showLoder} /> : <AccessDenied />} />
            <Route path="/org_details" element={hasPermission("Organization") ? <OrgDetails isLoader={showLoder} /> : <AccessDenied />} />
            <Route path="/org_teacher_details" element={hasPermission("Organization") ? <OrgTeacherDetails isLoader={showLoder} /> : <AccessDenied />} />
            <Route path="/organization-applications-details" element={hasPermission("Organization") ? <OApplication isLoader={showLoder} /> : <AccessDenied />} />
            <Route path="/organization-identity-verification" element={hasPermission("Organization") ? <OIdentity isLoader={showLoder} /> : <AccessDenied />} />
            <Route path="/organization-agreement" element={hasPermission("Organization") ? <OAgreement isLoader={showLoder} /> : <AccessDenied />} />
            <Route path="/organization-authorization" element={hasPermission("Organization") ? <OAuthorization isLoader={showLoder} /> : <AccessDenied />} />
            <Route path="/org-teacher-background-details" element={hasPermission("Organization") ? <OrgTeacherBg isLoader={showLoder} /> : <AccessDenied />} />
            <Route path="/org-teacher-identity-details" element={hasPermission("Organization") ? <OrgTeacherIdentity isLoader={showLoder} /> : <AccessDenied />} />
            <Route path="/org-profile" element={hasPermission("Organization") ? <OrgProfile isLoader={showLoder} /> : <AccessDenied />} />

            <Route path="/category" element={hasPermission("Categories") ? <Category isLoader={showLoder} /> : <AccessDenied />} />
            <Route path="/category-details" element={hasPermission("Categories") ? <CategoryDetails isLoader={showLoder} /> : <AccessDenied />} />
            <Route path="/add-category" element={hasPermission("Categories") ? <AddCategory isLoader={showLoder} /> : <AccessDenied />} />
            <Route path="/edit-category" element={hasPermission("Categories") ? <EditCategory isLoader={showLoder} /> : <AccessDenied />} />

            <Route path="/subject" element={hasPermission("Subjects") ? <Subject isLoader={showLoder} /> : <AccessDenied />} />
            <Route path="/subject-details" element={hasPermission("Subjects") ? <SubjectDetails isLoader={showLoder} /> : <AccessDenied />} />
            <Route path="/add-subject" element={hasPermission("Subjects") ? <AddSubject isLoader={showLoder} /> : <AccessDenied />} />
            <Route path="/edit-subject" element={hasPermission("Subjects") ? <EditSubject isLoader={showLoder} /> : <AccessDenied />} />

            <Route path="/sub_admin_list" element={hasPermission("Role Base Access") ? <SubAdminList isLoader={showLoder} /> : <AccessDenied />} />
            <Route path="/create_sub_admin" element={hasPermission("Role Base Access") ? <CreateSubAdmin isLoader={showLoder} /> : <AccessDenied />} />
            <Route path="/update_sub_admin" element={hasPermission("Role Base Access") ? <UpdateSubAdmin isLoader={showLoder} /> : <AccessDenied />} />
            <Route path="/sub_admin_details" element={hasPermission("Role Base Access") ? <SubAdminDetails isLoader={showLoder} /> : <AccessDenied />} />

            <Route path="/blog" element={hasPermission("Blogs") ? <Blog isLoader={showLoder} /> : <AccessDenied />} />
            <Route path="/blog-details" element={hasPermission("Blogs") ? <BlogDetails isLoader={showLoder} /> : <AccessDenied />} />
            <Route path="/add-blog" element={hasPermission("Blogs") ? <AddBlog isLoader={showLoder} /> : <AccessDenied />} />
            <Route path="/edit-blog" element={hasPermission("Blogs") ? <EditBlog isLoader={showLoder} /> : <AccessDenied />} />

            <Route path="/seo-user" element={hasPermission("SEO Users") ? <SeoUser isLoader={showLoder} /> : <AccessDenied />} />
            <Route path="/seo-user-details" element={hasPermission("SEO Users") ? <SeoDetails isLoader={showLoder} /> : <AccessDenied />} />
            <Route path="/add-seo-user" element={hasPermission("SEO Users") ? <AddSeoUser isLoader={showLoder} /> : <AccessDenied />} />
            <Route path="/edit-seo-user" element={hasPermission("SEO Users") ? <EditSeoUser isLoader={showLoder} /> : <AccessDenied />} />

            <Route path="/faq" element={hasPermission("FAQ's - Training Videos") ? <Faq isLoader={showLoder} /> : <AccessDenied />} />
            <Route path="/add-faq" element={hasPermission("FAQ's - Training Videos") ? <AddFaq isLoader={showLoder} /> : <AccessDenied />} />
            <Route path="/edit-faq" element={hasPermission("FAQ's - Training Videos") ? <EditFaq isLoader={showLoder} /> : <AccessDenied />} />
            <Route path="/video" element={hasPermission("FAQ's - Training Videos") ? <Video isLoader={showLoder} /> : <AccessDenied />} />
            <Route path="/video-details" element={hasPermission("FAQ's - Training Videos") ? <VideoDetails isLoader={showLoder} /> : <AccessDenied />} />
            <Route path="/add-video" element={hasPermission("FAQ's - Training Videos") ? <AddVideo isLoader={showLoder} /> : <AccessDenied />} />
            <Route path="/edit-video" element={hasPermission("FAQ's - Training Videos") ? <EditVideo isLoader={showLoder} /> : <AccessDenied />} />

            <Route path="/pages" element={hasPermission("Pages") ? <Pages /> : <AccessDenied />} />

            <Route path="/contactus-list" element={hasPermission("ContactUs Details") ? <ContactUs isLoader={showLoder} /> : <AccessDenied />} />
            <Route path="/contactus-details" element={hasPermission("ContactUs Details") ? <ContactUsDetails isLoader={showLoder} /> : <AccessDenied />} />
            <Route path="/contactus_reply" element={hasPermission("ContactUs Details") ? <ContactusReply isLoader={showLoder} /> : <AccessDenied />} />

            <Route path="/earnings" element={hasPermission("Earnings") ? <Earning isLoader={showLoder} /> : <AccessDenied />} />

            <Route path="/notifications" element={hasPermission("Notifications") ? <Notification isLoader={showLoder} /> : <AccessDenied />} />
            <Route path="/send_notifications" element={hasPermission("Notifications") ? <SendNotification isLoader={showLoder} /> : <AccessDenied />} />

            <Route path="/reports" element={hasPermission("Reports") ? <Reports /> : <AccessDenied />} />
            <Route path="/top_10_teacher_report" element={hasPermission("Reports") ? <FirstReport isLoader={showLoder} /> : <AccessDenied />} />
            <Route path="/top_10_organization_report" element={hasPermission("Reports") ? <SecondReport isLoader={showLoder} /> : <AccessDenied />} />
            <Route path="/total_payment_to_teacher_organization_report" element={hasPermission("Reports") ? <FourthReport isLoader={showLoder} /> : <AccessDenied />} />
            <Route path="/total_review_received" element={hasPermission("Reports") ? <FifthReport isLoader={showLoder} /> : <AccessDenied />} />
            <Route path="/teacher_org_joined_from_each_country" element={hasPermission("Reports") ? <SixthReport isLoader={showLoder} /> : <AccessDenied />} />
            <Route path="/learner_joined_from_each_country" element={hasPermission("Reports") ? <SeventhReport isLoader={showLoder} /> : <AccessDenied />} />
            <Route path="/enrollement_for_each_subject" element={hasPermission("Reports") ? <EightReport isLoader={showLoder} /> : <AccessDenied />} />
            <Route path="/complaint_received" element={hasPermission("Reports") ? <NineReport isLoader={showLoder} /> : <AccessDenied />} />
            <Route path="/complaint_details" element={hasPermission("Reports") ? <ComplaintDetails isLoader={showLoder} /> : <AccessDenied />} />
            <Route path="/top_25_most_popular courses" element={hasPermission("Reports") ? <TenReport isLoader={showLoder} /> : <AccessDenied />} />

            <Route path="/new-classes" element={hasPermission("New Classes") ? <Classroom isLoader={showLoder} /> : <AccessDenied />} />
            <Route path="/class_detail" element={hasPermission("New Classes") ? <ClassDetail isLoader={showLoder} /> : <AccessDenied />} />
            <Route path="/club_details" element={hasPermission("New Classes") ? <ClubDetails isLoader={showLoder} /> : <AccessDenied />} />
            <Route path="/quiz_details" element={hasPermission("New Classes") ? <QuizDetails isLoader={showLoder} /> : <AccessDenied />} />
            <Route path="/competition_details" element={hasPermission("New Classes") ? <CompetitionDetails isLoader={showLoder} /> : <AccessDenied />} />

            <Route path="/e-wallet" element={hasPermission("EWallet") ? <EWallet isLoader={showLoder} /> : <AccessDenied />} />
            <Route path="/ewallet_balance" element={hasPermission("EWallet") ? <EWalletBalance isLoader={showLoder} /> : <AccessDenied />} />
            <Route path="/refund_listing" element={hasPermission("EWallet") ? <RefundList isLoader={showLoder} /> : <AccessDenied />} />
            <Route path="/refund_request_details" element={hasPermission("EWallet") ? <RefundRequestDetails isLoader={showLoder} /> : <AccessDenied />} />
            <Route path="/ewallet_group_transactions" element={hasPermission("EWallet") ? <EwalletGroup isLoader={showLoder} /> : <AccessDenied />} />

            <Route path="/news_letter" element={hasPermission("Newsletter") ? <NewsLetter isLoader={showLoder} /> : <AccessDenied />} />
            <Route path="/subscription_users" element={hasPermission("Newsletter") ? <SubscriptionUsers isLoader={showLoder} /> : <AccessDenied />} />
            <Route path="/add_news_letter" element={hasPermission("Newsletter") ? <AddNewsLetter isLoader={showLoder} /> : <AccessDenied />} />
            <Route path="/preview_newsletter" element={hasPermission("Newsletter") ? <PreviewNewsLetter isLoader={showLoder} /> : <AccessDenied />} />
            <Route path="/news_letter_details" element={hasPermission("Newsletter") ? <NewsLetterDetails isLoader={showLoder} /> : <AccessDenied />} />

            <Route path="/messages" element={hasPermission("Messages") ? <Messages isLoader={showLoder} /> : <AccessDenied />} />

            <Route path="/coupons" element={hasPermission("Coupons") ? <Coupons isLoader={showLoder} /> : <AccessDenied />} />
            <Route path="/coupons_details" element={hasPermission("Coupons") ? <CouponDetails isLoader={showLoder} /> : <AccessDenied />} />

            <Route path="/new_leads" element={hasPermission("New Leads") ? <New_Leeds isLoader={showLoder} /> : <AccessDenied />} />
            <Route path="/new_leads_details" element={hasPermission("New Leads") ? <New_Leads_Details isLoader={showLoder} /> : <AccessDenied />} />
            <Route path="/preffered_teacher_details" element={hasPermission("New Leads") ? <PrefferedTeacherDetails isLoader={showLoder} /> : <AccessDenied />} />
            <Route path="/non_preffered_teacher_details" element={hasPermission("New Leads") ? <NonPrefferedTeacherDetails isLoader={showLoder} /> : <AccessDenied />} />
            <Route path="/new_leads_class_details" element={hasPermission("New Leads") ? <NewLeadsClassDetails isLoader={showLoder} /> : <AccessDenied />} />
            <Route path="/statistics" element={hasPermission("New Leads") ? <Statistics isLoader={showLoder} /> : <AccessDenied />} />
            <Route path="/learners_classes_list" element={hasPermission("New Leads") ? <NewLeadsClassLearner isLoader={showLoder} /> : <AccessDenied />} />
            <Route path="/responded_teacher_list" element={hasPermission("New Leads") ? <RespondedTeacherList isLoader={showLoder} /> : <AccessDenied />} />

            <Route path="*" element={<RedirectBlankPageHome />} />
          </Routes>
        </>
      );
    }
  };

  return <>{getFlow()}</>;
}

export default App;
